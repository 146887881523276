import * as React from 'react'
import PropTypes from 'prop-types'
import { Script } from 'gatsby'
import loadable from '@loadable/component'

const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const PageBuilder = loadable(() => import('/src/components/PageBuilder'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const PageBuilderPage = ({ post, utm }) => {
    const useAlternativeBranding = post.acfPage.useAlternativeBranding
    const branding = post.acfPage.branding

    return (
        <LayoutInner
            isFrontPage={post.isFrontPage}
            removeHeader={post.acfPage.removeHeader}
            removeMainNav={post.acfPage.removeMainNavigation}
            removeCta={post.acfPage.removeCta}
            useAlternativeBranding={useAlternativeBranding}
            branding={branding}
        >
            {post.acfPage.addExternalScript && <Script src={post.acfPage.scriptSourceUrl} />}

            <Seo post={post} />

            <main className={`c-main ${useAlternativeBranding ? `c-main--${branding}` : ''}`}>
                <article itemScope itemType="http://schema.org/Article">
                    <PageBuilder post={post} isFrontPage={post.isFrontPage} utm={utm} />
                </article>
            </main>
        </LayoutInner>
    )
}

PageBuilderPage.propTypes = {
    /**
     * Post object
     */
    post: PropTypes.object.isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

export default PageBuilderPage
